// Home.js
import React from "react";
import { NavLink } from "react-router-dom";
import registerImage from "../images/register.jpg";
import ordersImage from "../images/orders.jpg";
import addDelivery from "../images/addDeliveryBoy.jpg";
import assigned from "../images/assigned.jpg";
import delivered from "../images/delivered.jpg";
import traking from "../images/tracking.jpg";
import orderReport from "../images/report.jpg";
import signoutImage from "../images/signout.jpg";

import { FaRegistered } from "react-icons/fa";
const menuItems = [
  // { path: "/", label: "register" },
  {
    path: "/register",
    label: `Rgister`,
    background: registerImage,
  },
  { path: "/Orders", label: "Orders", background: ordersImage },
  { path: "/Assigned", label: "Assigned", background: assigned },
  { path: "/Confirmed", label: "Delivered", background: delivered },
  {
    path: "/addDeliveryBoy",
    label: "Register Delivery Boy",
    background: addDelivery,
  },
  { path: "/mapDeliveryBoy", label: "Track Delivery ", background: traking },
  { path: "/report", label: "Report", background: orderReport },
  // { path: "/services", label: "Services" },
  { path: "/logout", label: "Logout", background: signoutImage },
];

function AdminHome() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-8">
      {menuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className="relative group rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105"
        >
          {/* Background image */}
          <div
            className="h-48 bg-cover bg-center"
            style={{
              backgroundImage: `url(${item.background})`,
            }}
          ></div>
          {/* Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <h3 className="text-emerald-400 text-2xl font-bold shadow-lg">
              {item.label}
            </h3>
          </div>
        </NavLink>
      ))}
    </div>
  );
}

export default AdminHome;
