// import AWS from "aws-sdk";
// import axios from "axios";
// import { API } from "../utils/apk";
// import { toast } from "react-toastify";
// import { useState } from "react";
// // const dotenv = require("dotenv");
// // dotenv.config();
import { useEffect } from "react";

// const accessKeyId = process.env.REACT_APP_ACCESS_KEY;
// const secretAccessKey = process.env.REACT_APP_SECRET_KEY;
// const region = process.env.REACT_APP_REGION;
// const bucketName = process.env.REACT_APP_S3_BUCKET;
// console.log("Access Key:", accessKeyId);
// console.log("Secret Key:", secretAccessKey);
// console.log("Region:", region);
// console.log("Bucket Name:", bucketName);

// console.log(
//   "process.env.REACT_APP_ACCESS_KEY",
//   process.env.REACT_APP_SECRET_KEY
// );
// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//   secretAccessKey: process.env.REACT_APP_SECRET_KEY,
// });

// export const handleImageChange = async (e, setImageURL, selectedImage) => {
//   const file = e.target.files[0];

//   if (file) {
//     const validImageTypes = [
//       "image/jpeg",
//       "image/jpg",
//       "image/png",
//       "image/gif",
//       "image/jpg",
//       "image/webp",
//       "image/tiff",
//       "image/bmp",
//       "image/svg+xml",
//     ];
//     if (!validImageTypes.includes(file.type)) {
//       console.error("Invalid file type. Please upload an image.");
//       toast.error("Invalid file type. Please upload an image.");
//       setImageURL(null);
//       return;
//     }

//     const customerID = "66925640c4a9c61a716cc8fd_Products";

//     try {
//       selectedImage(URL.createObjectURL(file));
//       const blob = file;
//       const fileName = file.name.split("/").pop();
//       const key = `${customerID}/${fileName}`;

//       const s3 = new AWS.S3({
//         accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//         secretAccessKey: process.env.REACT_APP_SECRET_KEY,
//         region: process.env.REACT_APP_REGION,
//         Bucket: process.env.REACT_APP_S3_BUCKET,
//       });

//       const params = {
//         Bucket: process.env.REACT_APP_S3_BUCKET,
//         Key: key,
//         Body: blob,
//         ContentType: file.type,
//         Expires: 60 * 60 * 24 * 365 * 10,
//       };

//       const result = await s3.upload(params).promise();
//       console.log("Image Uploaded Successfully");
//       const getKey = result.key;
//       console.log("getKey====>", getKey);
//       console.log("getKey", getKey);
//       // getImageUrl(getKey, setImageURL);
//       regeneratePresignedUrl(getKey, setImageURL);
//       return result;
//     } catch (error) {
//       console.error("Error uploading file:", error);

//       if (error.code === "AccessControlListNotSupported") {
//         console.error("Bucket does not allow ACLs");
//       } else {
//         // Handle other potential errors
//       }

//       throw error;
//     }
//   } else {
//     setImageURL(null);
//   }
// };

// const regeneratePresignedUrl = async (key, setImageURL) => {
//   try {
//     // Ensure AWS credentials and region are configured
//     const s3 = new AWS.S3({
//       accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//       secretAccessKey: process.env.REACT_APP_SECRET_KEY,
//       region: process.env.REACT_APP_REGION,
//     });

//     const params = {
//       Bucket: process.env.REACT_APP_S3_BUCKET,
//       Key: key,
//       Expires: 60 * 60 * 24 * 7, // New expiration time: 7 days
//     };

//     // Generate a new pre-signed URL
//     const url = await s3.getSignedUrlPromise("getObject", params);

//     console.log("New pre-signed URL:", url);

//     // Update the image URL in state
//     setImageURL(url);

//     return url;
//   } catch (error) {
//     console.error("Error generating new pre-signed URL:", error);
//     throw error;
//   }
// };

// const handleImageLoadError = (key, setImageURL) => {
//   console.warn('Pre-signed URL expired. Regenerating...');
//   regeneratePresignedUrl(key, setImageURL);
// };

// useEffect(() => {
//   const interval = setInterval(() => {
//     regeneratePresignedUrl(fileKey, setImageURL); // Replace `fileKey` with your S3 object key
//   }, 7 * 24 * 60 * 60 * 1000 - 60000); // Refresh 1 minute before expiry

//   return () => clearInterval(interval);
// }, [fileKey]);

// const getImageUrl = async (key, setImageURL) => {
//   try {
//     console.log("key", key);
//     const response = await axios.post(API + "/get/s3/url", { key: key });
//     if (response.data) {
//       setImageURL(response.data.url);
//       console.log("response", response.data.url);
//     }
//   } catch (err) {
//     console.log("error while uploading Image", err);
//   }
// };

import AWS from "aws-sdk";

import { toast } from "react-toastify";

const accessKeyId = process.env.REACT_APP_ACCESS_KEY;
const secretAccessKey = process.env.REACT_APP_SECRET_KEY;
const region = process.env.REACT_APP_REGION;
const bucketName = process.env.REACT_APP_S3_BUCKET;

AWS.config.update({
  accessKeyId,
  secretAccessKey,
});

export const handleImageChange = async (e, setImageURL, selectedImage) => {
  const file = e.target.files[0];

  if (!file) {
    setImageURL(null);
    return;
  }

  const validImageTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/tiff",
    "image/bmp",
    "image/svg+xml",
  ];

  if (!validImageTypes.includes(file.type)) {
    toast.error("Invalid file type. Please upload an image.");
    setImageURL(null);
    return;
  }

  const customerID = "66925640c4a9c61a716cc8fd_Products";

  try {
    selectedImage(URL.createObjectURL(file));
    const fileName = file.name.split("/").pop();
    const key = `${customerID}/${fileName}`;

    const s3 = new AWS.S3({
      accessKeyId,
      secretAccessKey,
      region,
    });

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: file.type,
    };

    const result = await s3.upload(params).promise();
    console.log("Image Uploaded Successfully", result);

    regeneratePresignedUrl(result.Key, setImageURL);

    const intervalId = setInterval(() => {
      regeneratePresignedUrl(result.Key, setImageURL);
    }, 5 * 24 * 60 * 60 * 1000); // 5 days in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  } catch (error) {
    console.error("Error uploading file:", error);
    toast.error("Failed to upload image. Please try again.");
    throw error;
  }
};

const regeneratePresignedUrl = async (key, setImageURL) => {
  try {
    const s3 = new AWS.S3({
      accessKeyId,
      secretAccessKey,
      region,
    });

    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: 60 * 60 * 24 * 7, // 7 days
    };

    const url = await s3.getSignedUrlPromise("getObject", params);
    console.log("Generated Pre-signed URL:", url);
    setImageURL(url);
    console.log("url", url);
    return url;
  } catch (error) {
    console.error("Error generating pre-signed URL:", error);
    toast.error("Failed to retrieve image URL.");
    throw error;
  }
};
