import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import { API } from "../utils/apk";
import DriftMarker from "react-leaflet-drift-marker";

// Configure custom marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
});

// Component to fit map bounds around all delivery boys
const FitMapBounds = ({ deliveryBoys }) => {
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const map = useMap();
  useEffect(() => {
    if (!isMapInitialized && deliveryBoys.length > 0) {
      const bounds = L.latLngBounds(
        deliveryBoys.map((boy) => [boy.latitude, boy.longitude])
      );
      map.fitBounds(bounds); // Fit map to markers' bounds
      setIsMapInitialized(true); // Mark map as initialized
    }
  }, [deliveryBoys, isMapInitialized, map]);

  return null;
};
// Component to recenter the map
const RecenterMap = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center);
  }, [center, map]);

  return null;
};

const DeliveryBoyMap = () => {
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [selectedBoy, setSelectedBoy] = useState(null);
  const [places, setPlaces] = useState({}); // To store place names for each delivery boy
  const [recenterTrigger, setRecenterTrigger] = useState(false); // Control when to recenter
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const markerRefs = useRef({});
  const mapboxAccessToken =
    "pk.eyJ1Ijoic2FyYXRodXRyYW0iLCJhIjoiY201ZjRwcHg3NWxtZzJpcjBlOGllMDBhYSJ9.0-0GHwhLsixXVhWQ4qnsQA";

  // Fetch place name for a given latitude and longitude
  const fetchPlaceName = async (id, lat, lng) => {
    try {
      console.log(
        "urL------->",
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const geocodingUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
      const response = await axios.get(geocodingUrl);
      const placeName = response?.data?.display_name || "Unknown Location";

      // Update places state with the fetched place name
      setPlaces((prevPlaces) => ({ ...prevPlaces, [id]: placeName }));
    } catch (error) {
      console.error(`Error fetching place name for ID ${id}:`, error);
    }
  };

  useEffect(() => {
    let isFetching = false; // Prevent overlapping calls

    const fetchDeliveryBoys = async () => {
      if (isFetching) return; // Skip if already fetching
      isFetching = true;

      try {
        const response = await axios.get(`${API}/get/all/delivery/boy`);
        setDeliveryBoys(response?.data);

        // Fetch place names for each delivery boy in parallel
        await Promise.all(
          response?.data.map((boy) =>
            fetchPlaceName(boy._id, boy.latitude, boy.longitude)
          )
        );
      } catch (error) {
        console.error("Error fetching delivery boys:", error);
      } finally {
        isFetching = false; // Allow the next fetch
      }
    };

    // Fetch data initially
    fetchDeliveryBoys();

    // Poll every 5 seconds to get updated locations
    const intervalId = setInterval(fetchDeliveryBoys, 2000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleDeliveryBoyClick = (boy) => {
    setSelectedBoy(boy);
    setRecenterTrigger(true); // Trigger recentering for the selected boy

    // Open the popup for the selected delivery boy
    if (markerRefs.current[boy._id]) {
      markerRefs.current[boy._id].openPopup();
    }
  };
  return (
    <div className="flex flex-row h-screen mt-4 bg-gray-100 ">
      {/* Sidebar for listing delivery boys */}
      <div className="w-1/6 p-4 bg-white h-4/5  shadow-lg overflow-hidden">
        <div className="mt-4">
          <button
            className={`p-2 w-full rounded ${
              isSatelliteView ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setIsSatelliteView((prev) => !prev)}
          >
            {isSatelliteView
              ? "Switch to Street View"
              : "Switch to Satellite View"}
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">Delivery Boys</h2>
        <ul>
          {deliveryBoys.map((boy) => (
            <li
              key={boy._id}
              className={`p-2 cursor-pointer rounded ${
                selectedBoy && selectedBoy._id === boy._id
                  ? "bg-blue-500 text-white"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => handleDeliveryBoyClick(boy)}
            >
              {boy.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Map container */}
      <div className="flex-grow h-4/5 overflow-hidden">
        <MapContainer
          center={[22.5726, 88.3639]}
          zoom={10}
          className="h-full w-full"
        >
          {/* Tile layer from OpenStreetMap */}
          {/* <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          /> */}
          {isSatelliteView ? (
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
              attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>, &copy; OpenStreetMap contributors'
              tileSize={512}
              zoomOffset={-1}
              maxZoom={22}
            />
          ) : (
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
              attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>, &copy; OpenStreetMap contributors'
              tileSize={512}
              zoomOffset={-1}
              maxZoom={22}
            />
          )}

          {/* Fit map bounds to include all delivery boys */}
          <FitMapBounds deliveryBoys={deliveryBoys} />

          {/* Recenter map on selected delivery boy */}
          {selectedBoy && (
            <RecenterMap
              center={[selectedBoy.latitude, selectedBoy.longitude]}
              triggerRecenter={recenterTrigger}
            />
          )}

          {/* Plot delivery boys on the map */}
          {deliveryBoys.map((boy) => (
            <DriftMarker
              key={boy._id}
              position={[boy.latitude, boy.longitude]}
              ref={(ref) => (markerRefs.current[boy._id] = ref)}
              duration={3000}
            >
              <Popup>
                <div className="p-2">
                  <h3 className="text-lg font-semibold">{boy.name}</h3>
                  <p>Mobile: {boy.mobileNo}</p>
                  <p>Address: {boy.address}</p>
                  <p>Status: {boy.status}</p>
                  <p>
                    Location:{" "}
                    {places[boy._id] ? places[boy._id] : "Fetching..."}
                  </p>
                </div>
              </Popup>
            </DriftMarker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default DeliveryBoyMap;
