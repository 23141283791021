import React, { useEffect } from "react";
import notificationSound from "../../src/assets/notification.mp3";

const Notification = ({ message, onClose }) => {
 

  const playSound = () => {
    const audio = new Audio(notificationSound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error.message);
    });
  };
  useEffect(() => {
    playSound();
  }, []);

  return (
    <div className="fixed top-40 right-4 bg-green-500 text-white px-4 py-3 rounded-lg shadow-lg transition-opacity duration-300 animate-fade-in-out">
      <p>{message}</p>
    </div>
  );
};

export default Notification;
