import { getCityName } from "./getCityName";
export const getLocation = async () => {
  if ("geolocation" in navigator) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      const city = await getCityName(latitude, longitude);
      return city; // Properly returning the city name
    } catch (error) {
      console.warn("Geolocation failed, falling back to IP-based location.");
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      console.log(`IP: ${data.ip}`);
      console.log(`City: ${data.city}`);
      console.log(`Region: ${data.region}`);
      console.log(`Country: ${data.country_name}`);
      return data.city; // Returning the city from IP-based location
    }
  } else {
    console.warn("Geolocation is not supported, using IP-based location.");
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    console.log(`IP: ${data.ip}`);
    console.log(`City: ${data.city}`);
    console.log(`Region: ${data.region}`);
    console.log(`Country: ${data.country_name}`);
    return data.city; // Returning the city from IP-based location
  }
};
