export const numberToWords = (num) => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand"];

  if (num === 0) return "Zero";

  let words = "";

  const convertHundreds = (n) => {
    let str = "";
    if (n > 99) {
      str += units[Math.floor(n / 100)] + " Hundred ";
      n = n % 100;
    }
    if (n > 10 && n < 20) {
      str += teens[n - 10] + " ";
    } else {
      str += tens[Math.floor(n / 10)] + " ";
      str += units[n % 10] + " ";
    }
    return str.trim();
  };

  if (num >= 1000) {
    words += convertHundreds(Math.floor(num / 1000)) + " Thousand ";
    num = num % 1000;
  }

  words += convertHundreds(num);

  return words.trim().replace(/\s+/g, " ");
};
