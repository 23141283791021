const googleKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const getCityName = async (latitude, longitude) => {
  const apiKey = googleKey;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;
      console.log("addresscomponent", addressComponents);
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;

      console.log("City:", city || "City not found");
      return city;
    } else {
      console.error("Geocoding API error:", data.status);
    }
  } catch (error) {
    console.error("Error fetching city name:", error);
  }
};
