import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo2 from "../images/logo2.jpeg";
import { getLocation } from "../Helpers/getLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  // State to manage menu toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [location, setLocation] = useState();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const getLocations = async () => {
    const location = await getLocation();
    setLocation(location);
  };
  useEffect(() => {
    getLocations();
  }, []);

  return (
    <nav className="bg-blue-900 w-full h-20 px-4 flex justify-between items-center whitespace-wrap">
      {/* Logo */}
      <img src={logo2} alt="Logo 2" className="h-4/5 mr-8" />

      {/* Hamburger button for small screens */}
      <button
        className="text-white md:hidden focus:outline-none"
        onClick={toggleMenu}
      >
        <svg
          className="w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
          />
        </svg>
      </button>
      <div className="flex flex-col align-center">
        <div className="flex text-white justify-end  mb-4">
          {" "}
          <FontAwesomeIcon icon={faLocationDot} className="mr-2" />
          {location}
        </div>
        {/* Menu links displayed as list */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } absolute top-20 whitespace-wrap left-0 w-full bg-blue-900 md:bg-transparent md:relative  md:top-0 md:left-auto md:flex md:space-x-8 md:flex-row p-4 md:p-0 z-50`}
        >
          <ul className="flex flex-col md:flex-row md:space-x-8  space-y-4 md:space-y-0">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold whitespace-nowrap"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold whitespace-nowrap"
                }
              >
                {/* <FaHome className="mr-2" /> */}
                Home
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/register"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Register
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Orders"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Orders
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Assigned"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Assigned
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Confirmed"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Confirmed
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/addDeliveryBoy"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold whitespace-nowrap"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold whitespace-nowrap"
                }
              >
                Add Delivery Boy
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mapDeliveryBoy"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold whitespace-nowrap"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold whitespace-nowrap"
                }
              >
                Track Delivery
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/report"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Report
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/logout"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-400 border-2 border-green-400 px-4 rounded-md font-bold"
                    : "text-white hover:text-red-400 border-2 px-4 rounded-md font-bold"
                }
              >
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
