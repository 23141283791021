import React, { useState, useEffect } from "react";
import AdminHome from "./screens/AdminHome";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./screens/Login";
import Navbar from "./screens/Navbar";
import Register from "./screens/Register";
import ProtectedRoute from "./component/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import AddDeliveryBoy from "./screens/AddDeliveryBoy";
import Cookies from "js-cookie";
import Logout from "./screens/Logout";
import Signup from "./screens/Signup";
import OrderDetails from "./screens/OrderDetails";
import DeliveryReport from "./component/ref";
import ReportComponent from "./screens/ReportComponent";
import DeliveryBoyMap from "./screens/DeliveryBoyMap";
import ConfirmedOrders from "./screens/ConfirmedOrders";
import AssignedOrder from "./screens/AssignedOrder";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedAuthToken = Cookies.get("authToken");
    setIsAuthenticated(!!storedAuthToken);
    setLoading(false);
  }, []);

  const handleLogout = () => {
    Cookies.remove("authToken");
    setIsAuthenticated(false);
    window.location.href = "/login"; // Redirect after logout
  };

  if (loading) {
    return null; // Render nothing or a loading spinner while checking authentication
  }

  return (
    <Router>
      <div className="h-full bg-gray-100 flex flex-col">
        <ToastContainer />

        {isAuthenticated && (
          <div className="fixed w-full top-0 z-10">
            <Navbar onLogout={handleLogout} />
          </div>
        )}

        <div className="flex-1 mt-16">
          <Routes>
            {/* Protected Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <AdminHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addDeliveryBoy"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <AddDeliveryBoy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Assigned"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <AssignedOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Confirmed"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ConfirmedOrders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ReportComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Orders"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <OrderDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mapDeliveryBoy"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DeliveryBoyMap />
                </ProtectedRoute>
              }
            />
            <Route path="/register" element={<Register />} />

            {/* Public Routes */}
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/logout"
              element={<Logout onLogout={handleLogout} />}
            />

            {/* Redirect if not authenticated */}
            {!isAuthenticated && (
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
